import gql from 'graphql-tag';

export const CAROUSEL_QUERY = gql`
  query NewQuery($id: ID!) {
    page(id: $id) {
      title
      carousel {
        carousel {
          sourceUrl
        }
      }
    }
  }
`;
