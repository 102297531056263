import React from 'react';
import { Container } from 'react-bootstrap';
import CarSkeletons from '../CarSkeleton';

const BlogPageLoader = () => {
  return (
    <div>
      <Container>
        <section className='mt-5'>
          <CarSkeletons height='2rem' width='50%' margin=' 1rem' />
          <CarSkeletons height='1rem' width='20%' margin=' 1rem' />
          <CarSkeletons height='2rem' width='30%' margin=' 1rem' />
          <CarSkeletons height='4rem' width='100%' margin=' 1rem' />
          <CarSkeletons height='4rem' width='100%' margin=' 1rem' />
          <CarSkeletons height='4rem' width='100%' margin=' 1rem' />
        </section>
      </Container>
    </div>
  );
};

export default BlogPageLoader;
