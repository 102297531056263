import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import CarSkeletons from '../Skeletons/CarSkeleton';

const BlogsLoader = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col md={4}>
            <CarSkeletons height='6rem' width='100%' margin=' 1rem' />
          </Col>
          <Col md={4}>
            <CarSkeletons height='6rem' width='100%' margin=' 1rem' />
          </Col>
          <Col md={4}>
            <CarSkeletons height='6rem' width='100%' margin=' 1rem' />
          </Col>
          <Col md={4}>
            <CarSkeletons height='6rem' width='100%' margin=' 1rem' />
          </Col>
          <Col md={4}>
            <CarSkeletons height='6rem' width='100%' margin=' 1rem' />
          </Col>
          <Col md={4}>
            <CarSkeletons height='6rem' width='100%' margin=' 1rem' />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BlogsLoader;
