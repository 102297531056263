import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CarSkeletons from '../CarSkeleton';

const CarouselSkeleton = () => {
  return (
    <div>
      <Row>
        <Col lg={12}>
          <CarSkeletons height='35rem' width='100%' />
        </Col>
        {/* <Col lg={3}>
            <ul>
              <li>
                <CarSkeletons
                  height='7rem'
                  width='100%'
                  margin='0rem 0rem 1rem 0rem'
                />
              </li>
              <li>
                <CarSkeletons
                  height='7rem'
                  width='100%'
                  margin='0rem 0rem 1rem 0rem'
                />
              </li>
              <li>
                <CarSkeletons
                  height='7rem'
                  width='100%'
                  margin='0rem 0rem 1rem 0rem'
                />
              </li>
              <li>
                <CarSkeletons
                  height='7rem'
                  width='100%'
                  margin='0rem 0rem 1rem 0rem'
                />
              </li>
            </ul>
          </Col> */}
      </Row>
    </div>
  );
};

export default CarouselSkeleton;
